import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as baseStyles from "../index.module.css";
import * as styles from "./advantageous-pricing.module.css";

export default function SurchargeProgram() {
  return (
    <Layout>
      <Helmet>
        <title>Surcharge Program | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Surcharge Program</h1>
          <p>
            Offset your fees by charging an additional fee on credit card
            transactions
          </p>
        </div>

        <div className={baseStyles.pageContentInner}>
          <div className={styles.contentRoot}>
            <p>
              With a surcharge program, merchants charge an additional fee on
              credit card transactions only, which helps them offset the fees
              associated with processing those transactions.
            </p>
            <p>
              While this is the preferred program by the major card brands, it
              is not yet permitted by all 50 states. The states that currently
              do not allow surcharging are Colorado, Connecticut, Kansas, Maine,
              Massachusetts, and Oklahoma.
            </p>
            <p>
              If a merchant conducts business in multiple states, they can still
              surcharge, but only in states where it is legal.
            </p>
            <p>
              <b>
                You may be wondering, why would a merchant choose that program
                if it's just going to cost their customers more money?
              </b>
            </p>
            <p>
              That's a valid question, and of course, merchants take their
              customers' wallets into account. However, the industry consensus
              is that merchants who complete just one month of the program
              generally stick with it.
            </p>
            <p>
              This is because the amount of money a merchant can save on their
              processing fees by surcharging transactions outweighs the handful
              of customers who may balk at the fee.
            </p>
            <p>
              <b>
                When you explain the benefits and show the program's value, it's
                an easy sell for small to mid-sized retail stores, restaurants,
                service businesses, and more.
              </b>
            </p>
            <a className={baseStyles.heroCta} href={"/get-quote"}>
              Get Started
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
